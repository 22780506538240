import { Link } from "gatsby"
import React from "react"

import { AiFillCaretDown } from "react-icons/ai"
import { UncontrolledCollapse } from "reactstrap"

const Menu = () => {
  return (
    <>
      <div className="row flex-column">
        <div>
          <div
            className="toggle-btn d-flex align-items-center"
            onclick="void(0)"
            aria-label="services"
            id="services-menu"
          >
            <Link aria-label="link" to="/">
              services
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse toggler="#services-menu">
            <div className="d-flex flex-column collapse-content">
              <h5 className="sidebar-subtitle">Consulting</h5>
              <Link aria-label="link" to="/tech-consulting/">
                Technology Consulting
              </Link>
              <Link aria-label="link" to="/application-security-audit/">
                Application Security Audit
              </Link>
              <Link aria-label="link" to="/application-health-audit/">
                Application Health Audit
              </Link>
              <Link aria-label="link" to="/procurement-automation-services/">
                Procurement Automation
              </Link>
              <Link aria-label="link" to="/post-launch-enablement-services/">
                Post Launch Enablement
              </Link>
              <h5 className="sidebar-subtitle">Development</h5>
              <Link aria-label="link" to="/quandary-connect/">
                Integration Services
              </Link>
              <Link aria-label="link" to="/quandary-build/">
                Application Development
              </Link>
              <Link aria-label="link" to="/citizen-development-support/">
                Citizen Development
              </Link>
              <h5 className="sidebar-subtitle">Industry Focus</h5>
              <Link
                aria-label="link"
                to="/offline-application-development-construction-management/"
              >
                Construction
              </Link>
              <Link aria-label="link" to="/financial-services-automation/">
                Finance
              </Link>
              <Link aria-label="link" to="/patient-care-automation/">
                Healthcare
              </Link>
              <Link aria-label="link" to="/retail-automation/">
                Retail
              </Link>
              <Link aria-label="link" to="/transportation-and-logistics/">
                Transportation
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="products-menu"
          >
            <Link aria-label="link" to="/">
              products
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse
            // isOpen={isOpen === "products"}
            toggler="#products-menu"
          >
            <div className="d-flex flex-column collapse-content">
              <div className="d-flex align-items-center">
                <Link aria-label="link" to="/peaksuite/">
                  peaksuite
                </Link>
                <AiFillCaretDown id="products-peaksuite" />
              </div>
              <UncontrolledCollapse
                toggler="#products-peaksuite"
                style={{ marginLeft: "1rem" }}
              >
                <div className="d-flex flex-column">
                  <Link aria-label="link" to="/peaksuite/peaksuite-echo/">
                    echo
                  </Link>
                  <Link aria-label="link" to="/peaksuite/peaksuite-trailhead/">
                    Trailhead
                  </Link>
                  <Link aria-label="link" to="/peaksuite/peaksuite-compass/">
                    compass
                  </Link>
                  <Link aria-label="link" to="/peaksuite/peaksuite-basecamp/">
                    basecamp
                  </Link>
                  <Link
                    aria-label="link"
                    to="/peaksuite/peaksuite-docgenerator/"
                  >
                    document generator
                  </Link>
                  <Link
                    aria-label="link"
                    to="/peaksuite/peaksuite-pivot-table/"
                  >
                    peaksuite pivot table
                  </Link>
                </div>
              </UncontrolledCollapse>
              <Link aria-label="link" to="/quick-base-solutions/">
                Quickbase
              </Link>
              <Link aria-label="link" to="/workato-solutions/">
                Workato
              </Link>
              <Link aria-label="link" to="/pipefy/">
                Pipefy
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="resources"
          >
            <Link aria-label="link" to="/">
              resources
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse toggler="#resources">
            <div className="d-flex flex-column collapse-content">
              <Link aria-label="link" to="/knowledge-base/">
                knowledge base
              </Link>
              <Link aria-label="link" to="/news/">
                Blog Entries
              </Link>
              <Link aria-label="link" to="/case-studies/">
                case studies
              </Link>
              <Link aria-label="link" to="/faq/">
                faq
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
        <div>
          <div
            className="toggle-btn d-flex align-items-center mt-3"
            id="who-are-we-menu"
          >
            <Link aria-label="link" to="/">
              who are we
            </Link>
            <AiFillCaretDown />
          </div>
          <UncontrolledCollapse toggler="#who-are-we-menu">
            <div className="d-flex flex-column collapse-content">
              <Link aria-label="link" to="/about-us/">
                about us
              </Link>
              <Link aria-label="link" to="/our-approach/">
                our approach
              </Link>
              <Link aria-label="link" to="/careers/">
                Careers
              </Link>
              <Link aria-label="link" to="/contact/">
                Contact Us
              </Link>
            </div>
          </UncontrolledCollapse>
        </div>
      </div>
    </>
  )
}

export default Menu
